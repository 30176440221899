function padText(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function removerTildes (texto) {
  return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

function getStrForLen (str, len, lower = false) {
  if (lower) {
    return str.substring(0,len).toLowerCase()
  } else {
    return str.substring(0,len).toUpperCase()
  }
}

function getNameOrder (id) {
  try {
    // return id.substring(0,4).toUpperCase()
    return parseInt(id) ? parseInt(id).toString() : id.substring(0,4).toUpperCase() 
  } catch {
    return ''
  }
}

export { padText, removerTildes, getStrForLen, getNameOrder };
