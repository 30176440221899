import { db } from '@/plugins/firebase'
import { collection, addDoc, serverTimestamp } from "firebase/firestore"
import moment from 'moment';
import { getStrForLen, getNameOrder } from  '@/functions/utils/textFunctions'
import { aleatorio } from './utils/numFunctions';
import html2canvas from 'html2canvas';
import templateComanda from './printHtml/templateComanda';
import templateComprobante from './printHtml/templateComprobante';
import templateCuenta from './printHtml/templateCuenta';

const axios = require('axios').default;

const grabanIVA = [2,3,4,8]

function fusionArray (arrayData, len) {
  let text = ''
  for (let index = 0; index < arrayData.length; index++) {
    text = text + arrayData[index];
    if (index < arrayData.length - 1) {
      text = text + ", "
    }
  }
  return getStrForLen(text, len)
}

async function imprimirComanda(orderId, dataOrden, items, negocio, user, imprimirValores, notaAdicional = '', descripcion = 'Nueva Orden') {
  try {
    if (negocio.imprimirTickets) {
      let formaPago = ''
      if (dataOrden.tipoFormaPago.text) {
        formaPago = dataOrden.tipoFormaPago.text + ' - ' + dataOrden.tipoFormaPago.tipo
      }
      const dataComanda = {
        id: getNameOrder(orderId),
        comanda: {
          id: orderId,
          codigo:  getStrForLen(dataOrden.nombre, 16),
          mesero:  getStrForLen(user.nombre, 35),
          mesa:  getStrForLen(dataOrden.mesa + '-' + dataOrden.localidad),
          numeroPersonas:  dataOrden.personas.toString(),
          notaAdicional,
          fecha: moment().format('DD-MM-YYYY'),
          hora: moment().format('HH:mm'),
          numeroOrden: getNameOrder(orderId),
          tipo:'',
          imprimirValores,
          monto: dataOrden.monto.toFixed(2).toString(),
          formaPago,
        },
        itemscomanda: []
      }
      const itemsComanda = []
      items.forEach(item => {
        if (item.preparacion) {
          itemsComanda.push({
            nombre: getStrForLen(item.nombre, 40),
            cantidad: 1,
            detalle: fusionArray(item.informacionAdicional, 85),
            ingredientes: getStrForLen(item.preparar, 85),
            tipo: item.tipo
          })
        }
      })
      if (itemsComanda.length)
      {
        await setPrintComanda(dataComanda, itemsComanda, negocio, user.id, descripcion)
      }
      const itemsOrden = []
      items.forEach(item => {
        itemsOrden.push({
          nombre: getStrForLen(item.nombre, 40),
          cantidad: 1,
          detalle: fusionArray(item.informacionAdicional, 85),
          ingredientes: getStrForLen(item.preparar, 85),
          tipo: item.tipo
        })
      })
      if (itemsOrden.length)
      {
        dataComanda.id = dataComanda.id + 'OR'
        await setPrintOrden(dataComanda, itemsOrden, negocio, user.id)
      }
    }
  } catch (er) {
    console.error('PRINT COMANDA: ', er)
    return
  }
}

async function setPrintComanda (dataComanda, itemsComanda, negocio, userId, descripcion) {
  const auxItems = itemsComanda
  if (negocio.separarBar) {
    const auxId = dataComanda.id
    dataComanda.numeroOrden = getNameOrder(dataComanda.id)
    let itemsCocina = []
    if (!negocio.snackToBar) {
      itemsCocina = auxItems.filter( e => e.tipo === 'Menú' || e.tipo === 'Snack')
    } else {
      itemsCocina = auxItems.filter( e => e.tipo === 'Menú')
    }
    if (itemsCocina.length && negocio.numeroImpresionComandasCocina > 0) {
      dataComanda.id = auxId + 'C' + aleatorio(1, 1000)
      dataComanda.comanda.tipo = 'Cocina'
      dataComanda.itemscomanda = itemsCocina
      await setPrint(
        userId,
        'TakeComandaCocina',
        descripcion,
        'imprimirComanda',
        dataComanda
      ) 
    }
    let itemsBar = []
    if (negocio.snackToBar) {
      itemsBar = auxItems.filter( e => e.tipo === 'Bebida' || e.tipo === 'Snack')
    } else {
      itemsBar = auxItems.filter( e => e.tipo === 'Bebida')
    }
    if (itemsBar.length && negocio.numeroImpresionComandasBar > 0) {
      dataComanda.id = auxId + 'B' + aleatorio(1, 1000)
      dataComanda.comanda.tipo = 'Bar'
      dataComanda.itemscomanda = itemsBar
      await setPrint(
        userId,
        'TakeComandaBar',
        descripcion,
        'imprimirComanda',
        dataComanda
      ) 
    }
  }
  else {
    if (negocio.numeroImpresionComandasCocina > 0)
    {
      dataComanda.id += 'C' + aleatorio(1, 1000)
      dataComanda.comanda.tipo = 'Cocina'
      dataComanda.itemscomanda = itemsComanda
      await setPrint(
        userId,
        'TakeComandaCocina',
        descripcion,
        'imprimirComanda',
        dataComanda
      )
    }
  }
}

async function setPrintOrden (dataComanda, itemsOrden, negocio, userId) {
  if (negocio.numeroImpresionOrden > 0) {
    dataComanda.comanda.tipo = 'Orden'
    dataComanda.itemscomanda = itemsOrden
    await setPrint(
      userId,
      'TakeOrden',
      'Orden Completa',
      'imprimirComanda',
      dataComanda
    )
  }
}

async function imprimirComprobante(dataComprobante, formaPago, photoUrl, comprobanteId, user, tipoComprobante, abrirCajonera = false) {
  try {
    let arrayItemns = []
    let subtotalIva0 = 0
    let subtotalIva12 = 0
    dataComprobante.items.forEach(item => {
      arrayItemns.push({
        "descripcion": getStrForLen(item.descripcion, 15, true),
        "cantidad": item.cantidad.toString(),
        "precio": item.precioUnitario.toString(),
        "descuento": item.descuento.toString(),
        "subtotal": item.precioTotalSinImpuesto.toString(),
      })
    })
    dataComprobante.totalImpuesto.forEach(impuesto => {
      if (impuesto.codigo === 2) {
        if (impuesto.codigoPorcentaje === 0) {
          subtotalIva0+=impuesto.baseImponible
        }
        if (grabanIVA.includes(impuesto.codigoPorcentaje)) {
          subtotalIva12+=impuesto.baseImponible
        }
      }
    })
    const dataPrint = {
      "negocio": {
        "logo": photoUrl,
        "nombre": getStrForLen(dataComprobante.nombreComercial, 35),
        "matriz": dataComprobante.matriz === 'SI',
        "ruc": dataComprobante.ruc,
        "direccionMatriz": getStrForLen(dataComprobante.direccionMatriz, 30),
        "direccionEstablecimiento": getStrForLen(dataComprobante.direccionEstablecimiento, 30),
        "telefono": dataComprobante.telefono,
        "contabilidad": dataComprobante.obligadoContabilidad,
        "contribuyenteEspecial": dataComprobante.contribuyenteEspecial,
        "nroResolucionRetencion": dataComprobante.nroResolucionRetencion,
        "regimenRimpe": dataComprobante.regimenRimpe,
        "negocioPopular": dataComprobante.negocioPopular,
        "mensaje": dataComprobante.mensajeTicket
      },
      "cliente": {
        "numeroDocumento": dataComprobante.cliente.numeroDocumento,
        "nombre":  getStrForLen(dataComprobante.cliente.nombre, 22),
        "telefono": dataComprobante.cliente.telefono,
        "email": dataComprobante.cliente.email,
        "direccion": getStrForLen(dataComprobante.cliente.direccion, 22)
      },
      "comprobante": {
        "tipo": tipoComprobante, /*******  FACTURA O RECIBO *************/
        "numeroAutorizacion": dataComprobante.claveAcceso ? dataComprobante.claveAcceso : '',
        "numeroComprobante": dataComprobante.numeroComprobante,
        "fecha": dataComprobante.fechaEmision,
        "hora": dataComprobante.horaEmision,
        "subtotal": dataComprobante.subtotal.toFixed(2).toString(),
        "subtotalIva0": subtotalIva0.toFixed(2).toString(),
        "subtotalIva12": subtotalIva12.toFixed(2).toString(),
        "impuestos": dataComprobante.impuestos.toFixed(2).toString(),
        "impuestosICE": dataComprobante.impuestosICE.toFixed(2).toString(),
        "impuestosIRBPNR": dataComprobante.impuestosIRBPNR.toFixed(2).toString(),
        "descuento": dataComprobante.totalDescuento.toFixed(2).toString(),
        "total": dataComprobante.total.toFixed(2).toString(),
        "formaPago": formaPago,
        "cajero": user.userName,
        "ambiente": dataComprobante.ambiente === 1 ? 'PRUEBAS' : 'PRODUCCION',
        "tipoEmision" : 'NORMAL' /******* TIPO DE EMISIÓN *************/,
        "numeroOrden": getNameOrder(dataComprobante.ordenId),
        "porcentajeIVA": dataComprobante.ivaVigente
      },
      'cajonera' : { 
        'abrir' : abrirCajonera
      },
      "items": arrayItemns
    }
    dataPrint.id = comprobanteId.substring(comprobanteId.length - 4) + aleatorio(1, 1000)
    const service = tipoComprobante  === 'RECIBO' ? 'imprimirRecibo' : 'imprimirRide'
    // console.log('dataPrint -> ',JSON.stringify(dataPrint))
    await setPrint(
      user.userId,
      'PagoComprobante',
      'Nuevo Pago',
      service,
      dataPrint
    )
  } catch (er) {
    console.error('SET PRINT: ', er)
  }
}

async function imprimirCuenta(dataComprobante, items, user) {
  try {
    let arrayItemns = []
    items.forEach(item => {
      arrayItemns.push({
        "descripcion": getStrForLen(item.nombre, 15, true),
        "cantidad": item.cantidad.toString(),
        "precio": item.precio.toString(),
        "descuento": item.descuento.toString(),
        "subtotal": item.precio.toString(),
      })
    })
    const dataPrint = {
      "negocio": {
        "nombre": getStrForLen(dataComprobante.nombreComercial, 35),
        "mensaje": dataComprobante.mensajeTicket
      },
      "comprobante": {
        "fecha": dataComprobante.fechaEmision,
        "hora": dataComprobante.horaEmision,
        "subtotal": dataComprobante.subtotal.toFixed(2).toString(),
        "impuestos": dataComprobante.impuestos.toFixed(2).toString(),
        "descuento": dataComprobante.descuento.toFixed(2).toString(),
        "total": dataComprobante.total.toFixed(2).toString(),
        "cajero": user.userName,
        "numeroOrden": getNameOrder(dataComprobante.ordenId)
      },
      'cajonera' : { 
        'abrir' : false
      },
      "items": arrayItemns
    }
    dataPrint.id = dataComprobante.ordenId.substring(dataComprobante.ordenId.length - 4) + aleatorio(1, 1000)
    const service = 'imprimirCuenta'
    await setPrint(
      user.userId,
      'CuentaOrden',
      'Cuenta',
      service,
      dataPrint
    )
  } catch (er) {
    console.error('SET PRINT: ', er)
  }
}
async function imprimirArqueoCaja(dataComprobante, items, user) {
  try {
    let arrayItemns = []
    items.forEach(item => {
      arrayItemns.push({
        "id": item.id,
        "comprobanteId": item.comprobanteId,
        "fecha": item.fecha,
        "hora": item.hora,
        "clienteId": item.clienteId,
        "clienteNombre": item.clienteNombre,
        "formaPago": item.formaPago,
        "total": item.total.toFixed(2).toString(),
        "estado": item.estado,
        "tipo": item.tipo,
      })
    })
    const dataPrint = {
      "negocio": {
        "nombre": getStrForLen(dataComprobante.nombreComercial, 35),
      },
      "comprobante": {
        "idCaja" : dataComprobante.idCaja,
        "fechaApertura" : dataComprobante.fechaApertura,
        "fechaCierre" : dataComprobante.fechaCierre,
        "cajero" : dataComprobante.cajero,
        "estado" : dataComprobante.estado,
        "totalEfectivo" : dataComprobante.totalEfectivo.toFixed(2).toString(),
        "totalTCredito" : dataComprobante.totalTCredito.toFixed(2).toString(),
        "totalTDebito" : dataComprobante.totalTDebito.toFixed(2).toString(),
        "totalBancos" : dataComprobante.totalBancos.toFixed(2).toString(),
        "totalEfectivoE" : dataComprobante.totalEfectivoE.toFixed(2).toString(),
        "totalTCreditoE" : dataComprobante.totalTCreditoE.toFixed(2).toString(),
        "totalTDebitoE" : dataComprobante.totalTDebitoE.toFixed(2).toString(),
        "totalBancosE" : dataComprobante.totalBancosE.toFixed(2).toString(),
        "totalIngresos" : dataComprobante.totalIngresos.toFixed(2).toString(),
        "totalEgresos" : dataComprobante.totalEgresos.toFixed(2).toString(),
        "total" : dataComprobante.total.toFixed(2).toString(),
        "fecha" : dataComprobante.fecha,
        "usuario" : user.userName,
      },
      "items": arrayItemns
    }
    dataPrint.id = dataComprobante.idCaja.substring(dataComprobante.idCaja.length - 4) + aleatorio(1, 1000)
    const service = 'imprimirArqueoCaja'
    await setPrint(
      user.userId,
      'ArqueoCaja',
      'Reporte de Caja',
      service,
      dataPrint
    )
  } catch (er) {
    console.error('SET PRINT: ', er)
  }
}

async function setPrint (userId, origin, title, service, dataPost, state = 'Nueva') {
  try {
    console.info(
      origin,
      title,
      service,
      dataPost,
      state,
      userId,
    )
    if (userId && title ) {
      return await addDoc(collection(db, "prints"), {
        origin,
        title,
        service,
        dataPost,
        state,
        deleted: false,
        userId,
        updated: new serverTimestamp,
        date: new serverTimestamp
      })
      .then((doc) => {
        return doc
      })
    } else {
      throw 'No hay datos para procesar la impresión'
    }
  } catch (er) {
    console.error('error: -> ', er)
    return null
    // throw 'Error al generar la impresion'
  }
}

async function printDocument (service, data, puerto = '5000') {
  try {
    let	servicio = 'http://localhost:' + puerto + '/' + service
    const dataComanda = JSON.stringify(data).toString()
    const dataS = dataComanda.replaceAll('"',"'")
    var config = {
      method: 'post',
      url: servicio,
      headers: { 
        'Content-Type': 'application/json',
      },
      data : dataS
    };
    return await axios(config)
    .then((result)=> {
      return result
    })
  } catch (er) {
    // console.error('error: -> ', er)
    return null
  }
}


async function htmlToImage(service, data, title) {
  // 1. Generar el HTML en texto con la plantilla
  let htmlDoc = null
  if (service === 'imprimirRide') {
    htmlDoc = templateComprobante(data); // FACTURA
  } else if (service === 'imprimirCuenta') {
    htmlDoc = templateCuenta(data); // CUENTA PARA LA MESA
  } else if (service === 'imprimirComanda') {
    htmlDoc = templateComanda(data, title); // COMANDA
  } else {
    console.info('NO COINCIDE EL TIPO DE IMPRESION', service)
    return null
  }
  // 2. Crear un contenedor temporal
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlDoc;
  
  // 3. Asegúrate de agregar el contenedor al DOM, pero que sea invisible
  tempDiv.style.position = 'absolute';
  tempDiv.style.left = '-9999px'; // Lo colocamos fuera de la vista
  document.body.appendChild(tempDiv);
  
  try {
      // 4. Usar html2canvas para convertir el contenido del contenedor en una imagen
      const canvas = await html2canvas(tempDiv); 
      const imageData = canvas.toDataURL('image/png');  // Convertir el canvas a base64 PNG

      // 5. Retornar el resultado como imagen en formato base64
      return imageData;
  } finally {
      // 6. Asegurarse de eliminar el contenedor temporal
      document.body.removeChild(tempDiv);
  }
}

async function printDocumentPi (service, data, title, serverPi) {
  try {
    await htmlToImage(service, data, title)
    .then(imageData => {
        // Enviar la imagen base64 al servidor
        const response = axios.post(serverPi + '/api/imprimir-imagen', {
        // const response = await axios.post(this.apiServer, {
          ip: data.impresora,  // IP DE LA IMPRESORA
          tipo: service,  // TIPO DE COMPROBANTE
          id: data.id,  // ID DEL COMPROBANTE
          imagen: imageData,  // IMAGEN EN FORMATO BASE64
          width: 550
        });
        // console.log('Ticket impreso correctamente como imagen');
        console.info(response.data);
      });
    } catch (error) {
      alert('Error al imprimir el ticket como imagen');
      console.error(error);
    }
}

export default { setPrint, printDocument, imprimirComprobante, imprimirComanda, imprimirCuenta, imprimirArqueoCaja, printDocumentPi }