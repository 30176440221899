<template>
  <div id="app">
    <DxDataGrid
      id="datagrid"
      ref="datagrid"
      :data-source="items"
      key-expr="id"
      :column-hiding-enabled="$vuetify.breakpoint.mdAndDown"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :word-wrap-enabled="true"
      :show-borders="true"
      :show-row-lines="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
      @selection-changed="onSelectionChanged"
    >
      <DxExport
        :enabled="true"
        :allow-export-selected-data="true"
      />
      <DxLoadPanel
        :enabled="true"
        :show-indicator="true"
      />
      <DxColumnChooser
        :enabled="true"
        mode="select"
      />
      <DxColumnFixing :enabled="true"/>
      <DxEditing
        :use-icons="true"
        mode="row"
      />
      <DxColumn data-field="id" caption="Id" data-type="string" cell-template="cell-id">
      </DxColumn>
      <template #cell-id="{ data }">
        <span class="font-weight-bold" >
          {{ data.value }}
        </span>
      </template>
      <DxColumn data-field="nombre" caption="Nombre" data-type="string">
      </DxColumn>
      <DxColumn data-field="categoria" caption="Categoría" data-type="string">
      </DxColumn>
      <DxColumn data-field="existencia" caption="Existencia total" data-type="decimal" alignment="right" cell-template="cell-existencia">
      </DxColumn>
      <template #cell-existencia="{ data }">
        <div>
          <span class="subtitle-2 con-alerta">
            {{ data.value  }}
          </span>
        </div>
      </template>
      <DxColumn data-field="transito" caption="En tránsito" data-type="decimal" :visible="false">
      </DxColumn>
      <DxColumn data-field="unidad" caption="Unidad" data-type="string" :visible="false">
      </DxColumn>
      <DxColumn data-field="descripcion" caption="Descripcion" data-type="string" :visible="false">
      </DxColumn>
      <DxColumn data-field="alertas" caption="Bodegas con alertas" data-type="decimal" cell-template="cell-alertas">
      </DxColumn>
      <template #cell-alertas="{ data : item }">
        <div>
          <v-chip
            v-for="x in item.data.alertas"
            :key="x"
            label
            color="primary"
            small
            class="mx-1"
            @click="kardexItem(x, item.data.id)"
          >
            {{ x }}
          </v-chip>
        </div>
      </template>
      <DxColumn data-field="precio" caption="Precio" data-type="decimal" cell-template="cell-precio">
      </DxColumn>
      <template #cell-precio="{ data }">
        <span class="">
          $ {{ data.value | valorMonetario}}
        </span>
      </template>
      <DxColumn type="buttons" caption="Acciones">
        <DxButton
          icon="print"
          hint="Imprimir cédula"
          :on-click="prepararPDF"
        />
      </DxColumn>
      <DxFilterRow v-if="state" :visible="state.filterPanel.filterEnabled"/>
      <DxHeaderFilter :visible="false"/>
      <DxGroupPanel
        :visible="$vuetify.breakpoint.mdAndUp"
        empty-panel-text="Agrupar columnas"
      />
      <DxGrouping
        :auto-expand-all="false"
        :context-menu-enabled="true"
        expand-mode="rowClick"
      />
      <DxPaging
        v-if="state"
        :page-size="state.pageSize"
        :page-index="state.pageIndex"
      />
      <DxPager
        :visible="true"
        :allowed-page-sizes=state.allowedPage
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="dx-grid-materiales-stock-storage"
      />
      <DxSelection
        select-all-mode="page"
        show-check-boxes-mode="onLongTap"
        mode="multiple"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import { exportDataGrid } from 'devextreme/excel_exporter'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { jsPDF as JsPDF } from 'jspdf'
import 'jspdf-autotable'
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter'

import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxPaging,
  DxPager,
  DxStateStoring,
  DxLoadPanel,
  DxColumnChooser,
  DxColumnFixing,
  DxExport,
  DxSelection,
  DxButton,
  DxEditing
} from 'devextreme-vue/data-grid'
import { mapGetters } from 'vuex'

export default {
  name: 'DataGridMateriales',
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxPager,
    DxStateStoring,
    DxLoadPanel,
    DxColumnChooser,
    DxColumnFixing,
    DxExport,
    DxSelection,
    DxButton,
    DxEditing,
  },
  props: {
    items: {
      type: Array,
      require: true
    }
  },
  data: () => ({
    loading: false,
    comprobantePDF: {
			id: null,
			data: null,
			generarPdf: false,
			url: null
		},
    selectionChangedBySelectBox: false,
    lastVisible: null,
    selectedRowsData: [],
    state: {
      allowedPage: [50, 100, 'all'],
      pageSize: 50,
      pageIndex: 0,
      filterPanel: {
        filterEnabled: true
      }
    },
    dataGrid: null,
  }),
  computed: {
    ...mapGetters(['userId']),
  },
  methods: {
    kardexItem (bodegaId, itemId) {
      try {
        if (bodegaId &&  itemId) {
          this.$emit('close')
          const ruta = {
            name: 'kardex-item-bodega',
            params: {
              bodegaId,
              id: itemId,
            }
          }
          this.$router.replace(ruta)
        }
      } catch (er) {
        console.error(er)
      }
    },
    prepararPDF (e) {
      try {
        const dataComprobante = {...e.row.data, index: e.row.rowIndex }
        this.comprobantePDF.id = dataComprobante.id
        this.comprobantePDF.data = dataComprobante
        this.comprobantePDF.generarPdf = true
        // console.log('comprobantePDF', this.comprobantePDF)
      } catch {
        return
      }
		},
    urlComprobante (urlPdf) {
			try {
        this.comprobantePDF.url = urlPdf
			} catch (er) {
				console.error('PDF ERR:', er)
				return false
			}
		},
    async getPDFClick (e) {
      try {
        this.loading = true
        const dataItem = {...e.row.data, index: e.row.rowIndex }
        window.open(dataItem.urlPdf);
      } finally {
        this.loading = false
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      if (!this.selectionChangedBySelectBox) {
        this.categoria = null;
      }

      this.selectedRowsData = selectedRowsData;
      this.selectionChangedBySelectBox = false;
    },
    onExporting (e) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('DATA')

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
        // selectedRowsOnly: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'stockMinimo_'+ this.getDate() +'.xlsx')
        })
      })
      e.cancel = true
    },
    getDate() {
      try {
        return this.$moment().format('YYYY-MM-DD_hh-mm')
      } catch {
        return ''
      }
    },
    exportPDFGrid () {
      const dataGrid = this.$refs.datagrid.instance;
      if (dataGrid) {
        const doc = new JsPDF()
        exportDataGridToPdf({
          jsPDFDocument: doc,
          component: dataGrid
        }).then(() => {
          doc.save('stockMinimo_'+ this.getDate() +'.pdf')
        })
      }
    },
    onToolbarPreparing (e) {
      try {
        e.toolbarOptions.items.unshift(
          {
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'exportpdf',
              hint: 'Exportar a PDF',
              onClick: this.exportPDFGrid.bind(this)
            }
          },
          {
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              hint: 'Recargar',
              onClick: this.getData.bind(this)
            }
          })
      } catch {
        return
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.con-alerta {
  padding: 5px 10px;
  background-color:  #ffe6e6;
  color: #ff6666;
  border-radius: 4px; 
  font-weight: bold;
}
</style>
