export default function templateComanda (data, title) {
let texto = '<div id="ticket" style="color: black;font-weight: 500;font-size: 14px; width: 300px; padding: 2px;">'
+'<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+'<p style="margin-bottom: 5px; font-weight: bold;text-align: center; font-size: 14px;">'+ title +'</p>'
+'<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+'<div style="margin-bottom: 1px; display: flex; justify-content: space-between"><span style="font-weight: bold;">Orden No. '+ data.comanda.numeroOrden +'</span> <span><span>Cocina</span></span></div>'
+'<p style="margin-bottom: 1px;">Mesa: <span>'+ data.comanda.mesa +'</span></p>'
+'<p style="margin-bottom: 1px;">Personas: <span>'+ data.comanda.numeroPersonas +'</span></p>'
+'<p style="margin-bottom: 1px;">Mesero: <span>'+ data.comanda.mesero +'</span></p>'
+'<div style="margin-bottom: 1px; display: flex; justify-content: space-between"><span>Fecha: '+ data.comanda.fecha +'</span><span>Hora: '+ data.comanda.hora +'</span></div>'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '<h3 style="margin-top: 5px; font-weight: normal;text-align: center; font-size: 14px">COMANDA</h3>'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+'<table style="text-align: left; font-size: 12px; width: 100%;">'
+'<thead>'
+'<tr>'
+'<th style="width: 70%;text-align: left;">'
+'<p style="margin-bottom: 1px;">NOMBRE</p>'
+'<p style="margin-bottom: 1px; font-size: 11px;">INGREDIENTES</p>'
+'</th>'
+'<th style="width: 15%;text-align: left;">'
+'<p style="margin-bottom: 1px;">CANTIDAD</p>'
+'<p style="margin-bottom: 1px;font-size: 11px; ">DETALLE</p>'
+'</th>'
+'</tr>'
+'</thead>'
+'<tbody>'
+'<tr>'
+'<td colspan="2">'
+'<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+'</td>'
+'</tr>'
 // Generar las filas dinámicamente según los items
data.itemscomanda.forEach(item => {
  texto +='<tr>'
  +'<td style="text-align: left;">'
  +'<p style="margin-bottom: 1px;">'+ item.nombre +'</p>'
  +'<p style="margin-bottom: 1px; font-size: 11px;">'+ item.ingredientes +'</p>'
  +'</td>'
  +'<td style="text-align: right;">'
  +'<p style="margin-bottom: 1px;">'+ item.cantidad +'</p>'
  +'<p style="margin-bottom: 1px; font-size: 11px">'+ item.detalle +'</p>'
  +'</td>'
  +'</tr>'
});
texto += '</tbody>'
+'</table>'
+'<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+'<p style="margin-bottom: 1px; margin-top: 10px; font-size: 12px;">Codigo: <span>'+ data.comanda.codigo +'</span></p>'
+'<p style="margin-top: 2px; font-size: 12px;">Nota: <span>'+ data.comanda.notaAdicional +'</span></p>'
+'<p style="margin-top: 15px;text-align: center; font-size: 12px;">--------------------- fin ---------------------</p>'
+'</div>'
return texto
}