<template>
  <span>
    <v-tooltip v-if="$vuetify.breakpoint.mdAndDown" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          @click="logOut()"
        >
          <v-icon>
            fa-arrow-right-from-bracket
          </v-icon>
        </v-btn>
      </template>
      <span>Cerrar sesión</span>
    </v-tooltip>

  </span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getAuth, signOut } from 'firebase/auth'
export default {
  name:"LogoutBtn",
  data: () => ({
    loading: false,
    itemsLength: 0
  }),
  computed: {
		...mapGetters(['userId']),
  },
  methods: {
    ...mapActions(['logout']),
    logOut () {
			try {
				const auth = getAuth()
				signOut(auth).then(() => {
					this.logout()
					this.$nextTick(()=>{
						this.$router.push('/')
					})
				})
			} catch {
				this.$nextTick(()=>{
					this.$router.push('/')
				})
			}
    }
  }
}
</script>
<style>
</style>