export default function templateComprobante (data) {
  let texto = '<div id="ticket" style="color: black;font-weight: 500;font-size: 11px; width: 300px; padding: 2px;">'
// + '<div style="display: flex; justify-content: center; padding: 5px 2px;">'
// + '<img src="./img/logos/'+ data.negocio.ruc +'_bn.jpg" style="max-width: 50%; width: 35%;  height: auto;" alt="">'
// + '</div>'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '<p style="margin-bottom: 5px; font-weight: bold;text-align: center; font-size: 14px;">'+ data.negocio.nombre +'</p>'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '<div style="margin-bottom: 1px; display: flex; justify-content: space-between">'
+'<span>RUC: '+ data.negocio.ruc +'</span> <span>Telf: <span>'+ data.negocio.telefono +'</span></span></div>'
+ '<p style="margin-bottom: 1px;">Matriz: <span>'+ data.negocio.direccionMatriz +'</span></p>'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '<p style="margin-bottom: 1px;text-align: center;">Detalle de compra</p>'
+ '<p style="margin-bottom: 1px;text-align: center;">Sin validez tributaria</p>'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '<p style="margin-bottom: 1px;">Sucursal: <span>'+ data.negocio.direccionEstablecimiento +'</span></p>'
+ '<p style="margin-bottom: 1px;">Factura No.<span>'+ data.comprobante.numeroComprobante +'</span></p>'
+ '<p style="margin-bottom: 1px;">Clave de Acceso:</p>'
+ '<p style="margin-bottom: 1px;">'+ data.comprobante.numeroAutorizacion +'</p>';
texto += data.negocio.contribuyenteEspecial ? '<p style="margin-bottom: 1px;">Contribuyente Especial: <span>'+ data.negocio.contribuyenteEspecial +'</span></p>' : '';
texto += data.negocio.nroResolucionRetencion ? '<p style="margin-bottom: 1px;">Agente Retención: <span>'+ data.negocio.nroResolucionRetencion +'</span></p>' : '';
texto += data.negocio.regimenRimpe && !data.negocio.negocioPopular ? '<p style="margin-bottom: 1px;"><span>CONTRIBUYENTE RÉGIMEN RIMPE</span></p>' : '';
texto += data.negocio.regimenRimpe && data.negocio.negocioPopular ? '<p style="margin-bottom: 1px;"><span>CONTRIBUYENTE NEGOCIO POPULAR - RÉGIMEN RIMPE</span></p>' : '';
texto += '<div style="margin-bottom: 1px; display: flex; justify-content: space-between">'
+ '<span>Tipo emisión: '+ data.comprobante.tipoEmision +'</span> <span>Ambiente: <span>'+ data.comprobante.ambiente +'</span></span></div>'
+ '<p style="margin-bottom: 1px;">Cajero: <span>'+ data.comprobante.cajero +'</span></p>'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '<p style="margin-bottom: 1px;">Documento: <span>'+ data.cliente.numeroDocumento +'</span></p>'
+ '<p style="margin-bottom: 1px;">Nombre: <span>'+ data.cliente.nombre +'</span></p>'
+ '<p style="margin-bottom: 1px;">Dirección: <span>'+ data.cliente.direccion +'</span></p>'
+ '<p style="margin-bottom: 1px;">Teléfono: <span>'+ data.cliente.telefono +'</span></p>'
+ '<div style="margin-bottom: 1px; display: flex; justify-content: space-between"><span>Fecha: '+ data.comprobante.fecha +'</span> <span>Hora: <span>'+ data.comprobante.hora +'</span></span></div>'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '<h3 style="margin-top: 5px; font-weight: normal;text-align: center; font-size: 14px">DETALLE DE PRODUCTOS</h3>'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '<table style="text-align: left; font-size: 11px; width: 100%;">'
+ '<thead>'
+ '<tr>'
+ '<th style="width: 45%;text-align: center;">Item</th>'
+ '<th style="width: 15%;text-align: center;">| Cant.</th>'
+ '<th style="width: 20%;text-align: center;">| Precio</th>'
+ '<th style="width: 20%;text-align: center;">| Subt.</th>'
+ '</tr>'
+ '</thead>'
+ '<tbody>'
+ '<tr>'
+ '<td colspan="4">'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '</td>'
+ '</tr>';
 // Generar las filas dinámicamente según los items
data.items.forEach(item => {
  texto += '<tr>'
      + `<td style="text-align: left;">${ item.descripcion }</td>`
      + `<td style="text-align: right;">${ item.cantidad }</td>`
      + `<td style="text-align: right;">$${ item.precio }</td>`
      + `<td style="text-align: right;">$${ item.subtotal }</td>`
      + '</tr>';
});
texto += '</tbody>'
+ '</table>'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '<table style="text-align: left; font-size: 11px; width: 100%;">'
+ '<tbody>'
+ '<tr>'
+ '<td style="text-align: right; width: 80%;">Base '+ data.comprobante.porcentajeIVA +'%</td>'
+ '<td style="text-align: right;"> $'+ data.comprobante.subtotalIva12 +'</td>'
+ '</tr>'
+ '<tr>'
+ '<td style="text-align: right; width: 80%;">IVA '+ data.comprobante.porcentajeIVA +'%</td>'
+ '<td style="text-align: right;">$'+ data.comprobante.impuestos +'</td>'
+ '</tr>'
+ '<tr>'
+ '<td style="text-align: right; width: 80%;">BASE 0%</td>'
+ '<td style="text-align: right;">$'+ data.comprobante.subtotalIva0 +'</td>'
+ '</tr>'
+ '<tr>'
+ '<td style="text-align: right; width: 80%;">Descuento:</td>'
+ '<td style="text-align: right;">$'+ data.comprobante.descuento +'</td>'
+ '</tr>'
+ '<tr>'
+ '<td style="font-size: 12px; text-align: right; width: 80%;">TOTAL:</td>'
+ '<td style="font-size: 13px; text-align: right;">$'+ data.comprobante.total +'</td>'
+ '</tr>'
+ '</tbody>'
+ '</table>'
+ '<div style="border-top: 1px dashed; margin: 8px 0px 10px;height: 1px"></div>'
+ '<p style="margin-bottom: 1px;">Correo cliente: <span>'+ data.cliente.email +'</span></p>'
+ '<p style="margin-bottom: 1px;">Orden No. <span>'+ data.comprobante.numeroOrden +'</span></p>'
+ '<p style="margin-bottom: 1px;">Forma de pago: <span>'+ data.comprobante.formaPago +'</span></p>'
+ '<p style="margin-top: 10px;text-align: center">'+ data.negocio.mensaje +'</p>'
+ '<p style="margin-top: 10px;text-align: center; font-weight: bold">www.taked.app</p>'
+ '<p style="margin-top: 9px;text-align: center;">--------------------- fin ---------------------</p>'
+ '</div>'
return texto
}