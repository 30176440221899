export default function templateCuenta (data) {
  let texto = '<div id="ticket" style="color: black;font-weight: 500;font-size: 11px; width: 300px; padding: 2px;">'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '<p style="margin-bottom: 5px; font-weight: bold;text-align: center; font-size: 14px;">'+ data.negocio.nombre +'</p>'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '<h3 style="margin-top: 5px; margin-bottom: 5px; font-weight: normal;text-align: center; font-size: 14px">DELLE DE CUENTA</h3>'
+ '<p style="margin-bottom: 1px;">Orden No. <span>'+ data.comprobante.numeroOrden +'</span></p>'
+ '<p style="margin-bottom: 1px;">Cajero: <span>'+ data.comprobante.cajero +'</span></p>'
+ '<div style="margin-bottom: 1px; display: flex; justify-content: space-between"><span>Fecha: '+ data.comprobante.fecha +'</span> <span>Hora: <span>'+ data.comprobante.hora +'</span></span></div>'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '<h3 style="margin-top: 5px; font-weight: normal;text-align: center; font-size: 14px">ÍTEMS</h3>'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '<table style="text-align: left; font-size: 11px; width: 100%;">'
+ '<thead>'
+ '<tr>'
+ '<th style="width: 45%;text-align: center;">Item</th>'
+ '<th style="width: 15%;text-align: center;">| Cant.</th>'
+ '<th style="width: 20%;text-align: center;">| Precio</th>'
+ '<th style="width: 20%;text-align: center;">| Subt.</th>'
+ '</tr>'
+ '</thead>'
+ '<tbody>'
+ '<tr>'
+ '<td colspan="4">'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '</td>'
+ '</tr>';
 // Generar las filas dinámicamente según los items
data.items.forEach(item => {
  texto += '<tr>'
      + `<td style="text-align: left;">${ item.descripcion }</td>`
      + `<td style="text-align: right;">${ item.cantidad }</td>`
      + `<td style="text-align: right;">$${ item.precio }</td>`
      + `<td style="text-align: right;">$${ item.subtotal }</td>`
      + '</tr>';
});
texto += '</tbody>'
+ '</table>'
+ '<div style="border-top: 1px dashed; margin: 4px 0px;height: 1px"></div>'
+ '<table style="text-align: left; font-size: 11px; width: 100%;">'
+ '<tbody>'
+ '<tr>'
+ '<td style="text-align: right; width: 80%;">Subtotal</td>'
+ '<td style="text-align: right;"> $'+ data.comprobante.subtotal +'</td>'
+ '</tr>'
+ '<tr>'
+ '<td style="text-align: right; width: 80%;">Impuestos</td>'
+ '<td style="text-align: right;">$'+ data.comprobante.impuestos +'</td>'
+ '</tr>'
+ '<td style="text-align: right; width: 80%;">Descuento:</td>'
+ '<td style="text-align: right;">$'+ data.comprobante.descuento +'</td>'
+ '</tr>'
+ '<tr>'
+ '<td style="font-size: 12px; text-align: right; width: 80%;">TOTAL:</td>'
+ '<td style="font-size: 13px; text-align: right;">$'+ data.comprobante.total +'</td>'
+ '</tr>'
+ '</tbody>'
+ '</table>'
+ '<div style="border-top: 1px dashed; margin: 8px 0px 10px;height: 1px"></div>'
+ '<p style="margin-top: 10px;text-align: center">'+ data.negocio.mensaje +'</p>'
+ '<p style="margin-top: 10px;text-align: center; font-weight: bold">www.taked.app</p>'
+ '<p style="margin-top: 9px;text-align: center;">--------------------- fin ---------------------</p>'
+ '</div>'
return texto
}