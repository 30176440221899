import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import createStore from '@/store'
import accessControl from '../middleware/accessControl';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/remember',
    name: 'remember',
    component: () => import('../views/RememberView.vue')
  },
  {
    path: '/export-csv',
    name: 'export-csv',
    component: () => import('@/views/app/utils/ExportCsvView'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/app/DashboardApp.vue'),
    meta: {
      autenticado: true
      // middleware: accessControl
    }
  },
  {
    path: '/backwindow',
    name: 'backwindow',
    component: () => import('@/views/app/backwindow/BackWindow.vue'),
    meta: {
      autenticado: true
      // middleware: accessControl
    }
  },
  {
    path: '/admin-orders',
    name: 'admin-orders',
    component: () => import('@/views/app/orders/AdminOrdensView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/my-orders',
    name: 'my-orders',
    component: () => import('@/views/app/orders/MyOrdensView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/order-divider',
    name: 'order-divider',
    component: () => import('@/views/app/orders/OrderDividerView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/order/:id',
    name: 'order',
    component: () => import('@/views/app/orders/OrderView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/take-order',
    name: 'ordenes',
    component: () => import('@/views/app/comanda/TakeComandaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/take-order-board',
    name: 'take-order-board',
    component: () => import('@/views/app/comanda-onboard/TakeComandaOnBoardView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kiosko',
    name: 'kiosko',
    component: () => import('@/views/app/comanda/KioskoTakeComandaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/app/config/CategoriesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/formaspago',
    name: 'formaspago',
    component: () => import('@/views/app/config/FormasPagoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/ingredientes',
    name: 'ingredientes',
    component: () => import('@/views/app/ingredientes/IngredientesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/ingrediente/:id',
    name: 'ingrediente',
    component: () => import('@/views/app/ingredientes/ItemIngredienteView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/detailingrediente/:id',
    name: 'detailingrediente',
    component: () => import('@/views/app/ingredientes/IgredienteDetailView'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kardex',
    name: 'kardex',
    component: () => import('@/views/app/inventario/ItemKardexView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/ingresos-egresos',
    name: 'ingresos-egresos',
    component: () => import('@/views/app/inventario/IngresosEgresosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/inventario',
    name: 'inventario',
    component: () => import('@/views/app/inventario/InventarioView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/item/:id',
    name: 'item',
    component: () => import('@/views/app/inventario/ItemInventarioView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/cambiarimpuestos',
    name: 'cambiarimpuestos',
    component: () => import('@/views/app/inventario/CambiarImpuestosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/proveedores',
    name: 'proveedores',
    component: () => import('@/views/app/proveedores/ProveedoresView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/proveedor/:id',
    name: 'proveedor',
    component: () => import('@/views/app/proveedores/ItemProveedorView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/app/users/UserProfile.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import('../views/app/config/GeneralConfigurationView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/configurar-utils',
    name: 'configurar-utils',
    component: () => import('../views/app/config/UtilsConfigurationView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/mesa/:id',
    name: 'mesa',
    component: () => import('../views/app/mesas/ItemMesaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/mesas',
    name: 'mesas',
    component: () => import('../views/app/mesas/MesasView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/rol',
    name: 'rol',
    component: () => import('../views/app/roles/GestionRol.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/rol/asignar-ruta',
    name: 'rolRuta',
    component: () => import('../views/app/roles/AsignarRutas.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/app/users/ManageUsers.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/password',
    name: 'password',
    component: () => import('../views/app/facturacion/PayPhoneView.vue'),
    // component: () => import('../views/app/users/ManagePassword.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/users/form',
    name: 'usersForm',
    component: () => import('../views/app/users/FormView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kitchen-room',
    name: 'kitchen-room',
    component: () => import('../views/app/comanda/KitchenView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/bar-room',
    name: 'bar-room',
    component: () => import('../views/app/comanda/BarRoomView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: () => import('../views/app/clientes/ClientView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/cliente/:id',
    name: 'cliente',
    component: () => import('../views/app/clientes/ItemClienteView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  // {
  //   path: '/facturacion',
  //   name: 'facturacion',
  //   component: () => import('../views/app/FacturacionElectronica.vue'),
  //   meta: {
  //     autenticado: true,
  //     middleware: accessControl
  //   }
  // },
  {
    path: '/facturacion/emitir-comprobante/:ordenId',
    name: 'facturacion',
    component: () => import('../views/app/facturacion/FacturacionView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/facturacion/emitir-recibo/:ordenId',
    name: 'recibo',
    component: () => import('../views/app/facturacion/RecibosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/facturacion-manual',
    name: 'facturacion-manual',
    component: () => import('../views/app/facturacion/FactuacionManualView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/comprobantes',
    name: 'comprobantes',
    component: () => import('../views/app/facturacion/ComprobantesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/comprobantes-admin',
    name: 'comprobantes-admin',
    component: () => import('../views/app/facturacion/AdminComprobantesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/ordenespagadas',
    name: 'ordenespagadas',
    component: () => import('../views/app/facturacion/OrdernesSoloPagadasView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/items-eliminados',
    name: 'items-eliminados',
    component: () => import('../views/app/fiscalizacion/ItemsEliminadosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/items-cortesia',
    name: 'items-cortesia',
    component: () => import('../views/app/fiscalizacion/ItemsRegaladosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/app/NotificationsView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/prints',
    name: 'prints',
    component: () => import('../views/app/PrintsView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/stocks',
    name: 'stocks',
    component: () => import('../views/app/ingredientes/IngredientesStockView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/gestionar-caja',
    name: 'gestionar-caja',
    component: () => import('../views/app/cajas/GestionarCajaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/detalle/:id',
    name: 'detalleCaja',
    component: () => import('../views/app/cajas/DetalleCajasView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/egresos-caja',
    name: 'egresos-caja',
    component: () => import('../views/app/cajas/EgresosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/egreso-caja/:id',
    name: 'egreso-caja',
    component: () => import('../views/app/cajas/ItemEgresoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/detalle/transacciones/:id',
    name: 'detalleTransacciones',
    component: () => import('../views/app/cajas/DetalleTransaccionesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kpi/topvendidos',
    name: 'topvendidos',
    component: () => import('../views/app/kpis/TopVendidosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kpi/usoinventario',
    name: 'usoinventario',
    component: () => import('../views/app/kpis/InventarioOrdenesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kpi/usoingredientes',
    name: 'usoingredientes',
    component: () => import('../views/app/kpis/IngredientesKardexView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/fidelizacion/parametros-fidelizacion',
    name: 'parametros-fidelizacion',
    component: () => import('../views/app/fidelizacion/ParametrosFidelizacionView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/tipos-descuentos',
    name: 'tipos-descuentos',
    component: () => import('../views/app/config/DescuentosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/payphone',
    name: 'payphone',
    component: () => import('../views/app/payphone/ComprobantesPayPhoneView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  //REPORTES
  {
    path: '/excel-comprobantes',
    name: 'excel-comprobantes',
    component: () => import('../views/app/reports/ComprobantesExelView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/excel-inventario',
    name: 'excel-inventario',
    component: () => import('../views/app/reports/InventarioExelView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/excel-materiales',
    name: 'excel-materiales',
    component: () => import('../views/app/reports/MaterialesExelView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  // MATERIALES
  {
    path: '/bodegas',
    name: 'bodegas',
    component: () => import('../views/app/materiales/BodegasView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/bodega/:id',
    name: 'bodega',
    component: () => import('../views/app/materiales/FormBodegaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/bodega-encargado/:id',
    name: 'bodega-encargado',
    component: () => import('../views/app/materiales/FormEncargadosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/bodega-layout/:id',
    name: 'bodega-layout',
    component: () => import('../views/app/bodegas/FormLayoutView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/categorias-item',
    name: 'categorias-item',
    component: () => import('../views/app/materiales/CategoriasItemView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/materiales',
    name: 'materiales',
    component: () => import('../views/app/materiales/ListarItemsView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/material/:id',
    name: 'material',
    component: () => import('../views/app/materiales/FormItemView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kardex-material/:id',
    name: 'kardex-material',
    component: () => import('../views/app/materiales/KardexItemView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kardexes',
    name: 'kardexes',
    component: () => import('../views/app/bodegas/KardexesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/ordenes-compra',
    name: 'ordenes-compra',
    component: () => import('../views/app/materiales/OrdenesCompraView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/orden-compra',
    name: 'orden-compra',
    component: () => import('../views/app/materiales/OrdenCompraView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kardex-ingreso/:id',
    name: 'kardex-ingreso',
    component: () => import('../views/app/bodegas/KardexIngresoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kardex-egreso',
    name: 'kardex-egreso',
    component: () => import('../views/app/bodegas/KardexEgresoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/barcode',
    name: 'barcode',
    component: () => import('../views/app/materiales/BarCodeView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/materialesenbodegas',
    name: 'materialesenbodegas',
    component: () => import('../views/app/materiales/MaterialesBodegaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/unidad-negocio/:id',
    name: 'unidad-negocio',
    component: () => import('../views/app/costeo/UnidadNegocioView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/unidades-negocio',
    name: 'unidades-negocio',
    component: () => import('../views/app/costeo/UnidadesNegocioView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/tipos-unidad-negocio',
    name: 'tipos-unidad-negocio',
    component: () => import('../views/app/costeo/TiposUnidadesNegocios.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/administrar-bodegas',
    name: 'administrar-bodegas',
    component: () => import('../views/app/bodegas/AdministrarBodegasView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/materiales-bodega/:id',
    name: 'materiales-bodega',
    component: () => import('../views/app/bodegas/MaterialesBodegaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kardex-item-bodega/:bodegaId/:id/',
    name: 'kardex-item-bodega',
    component: () => import('../views/app/bodegas/KardexItemBodega.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/bodega-auxiliares/:id',
    name: 'bodega-auxiliares',
    component: () => import('../views/app/bodegas/FormAuxiliaresView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/pickers',
    name: 'pickers',
    component: () => import('../views/app/egresos/FormPickersView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/egresos-cum',
    name: 'egresos-cum',
    component: () => import('../views/app/egresos/ListarEgresosConsumo.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/egreso-cum/:id',
    name: 'egreso.cum',
    component: () => import('../views/app/egresos/EgresoConsumoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/egreso-transito/:id/:userId',
    name: 'egreso-transito',
    component: () => import('../views/app/egresos/EgresoTransitoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/egresos-transito',
    name: 'egresos-transito',
    component: () => import('../views/app/egresos/ListarEgresosTransito.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/efectivizar-transito/:id',
    name: 'efectivizar-transito',
    component: () => import('../views/app/egresos/EfectivizarTransitoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/efectivizar-usomateriales/:id',
    name: 'efectivizar-usomateriales',
    component: () => import('../views/app/egresos/UsoMaterialesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/personas',
    name: 'personas',
    component: () => import('../views/app/person/PersonsView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/persona/:id',
    name: 'persona',
    component: () => import('../views/app/person/FormPersonView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  // PERSONAS Y EMPLEADOS
  {
    path: '/empleados',
    name: 'empleados',
    component: () => import('../views/app/person/EmpleadosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/empleado/:id',
    name: 'empleado',
    component: () => import('../views/app/person/FormEmpleadoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/documentacion-empleado/:id',
    name: 'documentacion-empleado',
    component: () => import('../views/app/person/DocumentosEmpleadoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/zonas',
    name: 'zonas',
    component: () => import('../views/app/zonas/ZonasView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/zona/:id',
    name: 'zona',
    component: () => import('../views/app/zonas/FormZonaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/cargos',
    name: 'cargos',
    component: () => import('../views/app/person/CargosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    
    }
  },
  // CAJA DE PAGOS
  {
    path: '/cajas-pagos',
    name: 'cajas-pagos',
    component: () => import('../views/app/cajapagos/ListarCajasPagos.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja-pagos/:id',
    name: 'caja-pagos',
    component: () => import('../views/app/cajapagos/CajaPagosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/movimientos-cajapagos/:id',
    name: 'movimientos-cajapagos',
    component: () => import('../views/app/cajapagos/MovimientosCajaPagos.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/mis-cajaspagos',
    name: 'mis-cajaspagos',
    component: () => import('../views/app/cajapagos/MisCajasPagos.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/ingreso-cajapagos/:id',
    name: 'ingreso-cajapagos',
    component: () => import('../views/app/cajapagos/IngresoCajaPagos.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/egreso-cajapagos/:id',
    name: 'egreso-cajapagos',
    component: () => import('../views/app/cajapagos/EgresoCajaPagos.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/detalle-movimiento-cajapagos/:id/:cajaId',
    name: 'detalle-movimiento-cajapagos',
    component: () => import('../views/app/cajapagos/DetalleMovimientoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/tipos-cajaspagos',
    name: 'tipos-cajaspagos',
    component: () => import('../views/app/cajapagos/TiposCajasPagos.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/tipos-pagoscajas',
    name: 'tipos-pagoscajas',
    component: () => import('../views/app/cajapagos/TiposPagosCajas.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  // ANALITICA
  // {
  //   path: '/analitica-maquinaria-tracking/:id',
  //   name: 'analitica-maquinaria-tracking',
  //   component: () => import('../views/app/analitica/AnaliticaTrackingView.vue'),
  //   meta: {
  //     autenticado: true,
  //     middleware: accessControl
  //   }
  // },
  // {
  //   path: '/analitica-maquinaria-mantenimientos/:id',
  //   name: 'analitica-maquinaria-mantenimientos',
  //   component: () => import('../views/app/analitica/AnaliticaMantenimientosView.vue'),
  //   meta: {
  //     autenticado: true,
  //     middleware: accessControl
  //   }
  // },
  {
    path: '/analitica-materiales',
    name: 'analitica-materiales',
    component: () => import('../views/app/analitica/AnaliticaMaterialesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/analitica-estados-financieros',
    name: 'analitica-estados-financieros',
    component: () => import('../views/app/analitica/AnaliticaFinancierosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  try {
    let usuario = null;
    await createStore().then((store) => {
      usuario = store.getters.logeado;
    });

    let autorizacion = to.matched.some((record) => record.meta.autenticado);
    if (autorizacion && !usuario) {
      next("/");
    } else if (!autorizacion && usuario) {
      next("/dashboard");
    } else if (autorizacion && usuario) {
      if (to.meta.middleware) {
        const middleware = to.meta.middleware;
        middleware({ next, to });
      } else {
        next();
      }
    } else {
      next();
    }
  } catch (er) {
    console.error('ROUTER ->', er)
  }
});

export default router
