<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="warning"
        offset-x="20"
        offset-y="20"
        class="mr-1"
        :content="stock.length"
        :value="stock.length"
      >
        <template v-slot:badge>
          <span class="small-text">{{ stock.length }}</span>
        </template>
        <v-btn
          icon
          :color="stock.length ? 'donateback' : 'grey'"
          v-bind="attrs"
            v-on="on"
          :disabled="!stock.length"
        >
          <v-icon>
            fa-cart-plus
          </v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-card tile>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>fa-times</v-icon>
        </v-btn>
        <v-toolbar-title class="font-weight-bold">Alertas de stock mínimo</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="dialog = false"
          >
            Listo
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="mt-5">
            <data-grid-materiales-stock :items="items" @close="dialog = false"></data-grid-materiales-stock>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { db } from '@/plugins/firebase'
import { collection, onSnapshot, query, orderBy, where } from "firebase/firestore"
import { mapGetters } from 'vuex'
import DataGridMaterialesStock from './DataGridMaterialesStock.vue'

export default {
  name: 'MantenimientoBtn',
  components: {
    DataGridMaterialesStock
  },
  data: () => ({
    dataStock: [],
    bodegas: [],
    suscribeBodegas: null,
    suscribeStock: null,
    flag: false,
    dialog: false
  }),
  created () {
    this.getBodegas()
  },
  computed: {
    ...mapGetters(['userId','personaId']),
    stock() {
      return  this.items.filter(x => x.alertas.length)
    },
    idsBodegas () {
      try {
        return this.bodegas.map(x => {
          return x.id
        })
      } catch {
        return []
      }
    },
    items () {
      const auxData = []
      this.dataStock.forEach(item => {
        item.alertas = []
        item.bodegas.forEach(x=> {
          if (x.existenciaMinima > 0 && x.existenciaMinima >= x.existencia) {
            item.alertas.push(x.id)
          }
        })
        if (item.alertas.length) {
          auxData.push(item)
        }
      })
      return auxData
    },
  },
  beforeDestroy () {
    if (this.suscribeBodegas) {
      this.suscribeBodegas()
    }
    if (this.suscribeStock) {
      this.suscribeStock()
    }
  },
  methods: {
    async getBodegas() {
      try {
        const queryData = query(collection(db, "bodegas"), where('encargadosId', 'array-contains' , this.personaId), where('active', '==' , true))
        this.bodegas = []
        this.suscribeBodegas = await onSnapshot(queryData, (querySnapshot) => {
          const dataAux = []
          querySnapshot.forEach((doc) => {
            dataAux.push({id:doc.id, ...doc.data()})
          })
          this.bodegas = dataAux
          if (this.bodegas.length) {
            this.getStocks()
          }
        },(er) => {
          console.error(er)
        })
      } catch (er) {
        console.error(er)
        this.bodegas = []
      }
    },
    async getStocks() {
      try {
        const queryData = query(collection(db, "materiales"), where('bodegasIdExistenciaMinima', 'array-contains-any', this.idsBodegas), where('active', '==' , true),orderBy('nombre', 'asc'))
        this.dataStock = []
        this.suscribeStock = await onSnapshot(queryData, (querySnapshot) => {
          const stockAux = []
          querySnapshot.forEach((doc) => {
            stockAux.push({id:doc.id, ...doc.data()})
          })
          this.dataStock = stockAux
        },(er) => {
          console.error(er)
        })
      } catch (er) {
        console.error(er)
        this.dataStock = []
      }
    }
  }
}
</script>
<style>
</style>
