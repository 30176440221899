export function obtenerCodigo (id) {
  try {
    return parseInt(id) ? parseInt(id).toString() : id.substring(0,4).toUpperCase() 
  } catch {
    return ''
  }
}

export function viewArray (items) {
  return items.join(", ")
}