<template>
  <v-card hover shaped @click="verDataItem()">
    <v-avatar
      :key="producto.id"
      tile
      :height="heightImg"
      width="100%"
    >
      <v-img
        eager
        referrerpolicy="no-referrer"
        v-bind:src="photoURL"
        class="image-item"
        lazy-src="/no_image.png"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0" align="center" justify="center"
          >
            <v-progress-circular
              indeterminate
              size="30"
              width="2"
              color="grey"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-avatar>
    <v-card-text class="black--text pb-0">
      <v-row dense>
        <v-col cols="12">
          <p class="mb-1 subtitle-1 font-weight-bold primary--text title-product" style="line-height:1.1rem">{{ producto.nombre }}</p>
          <p v-if="producto.descripcion" class="mb-2 descrition-product" style="line-height:1.1rem">{{ producto.descripcion }}</p>
        </v-col>
      </v-row>
      <v-row dense v-if="producto.ingredientesMenu.length">
        <v-col cols="12">
          <v-btn
            color="black"
            tile
            block
            small
            text
            class="caption rounded-t"
            :class="$vuetify.breakpoint.xsOnly ? 'ml-n1':''"
            @click.stop="reveal = !reveal"
          >
            Ver ingredientes
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions :class="producto.descuento ? 'primary white--text' : 'grey lighten-5'">
      <v-row dense class="justify-space-between align-center">
        <v-col cols="auto" class="d-flex align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="loading"
                :color="producto.descuento  ? 'white' : producto.likes === 0  ? '' : 'primary'"
                icon
                v-bind="attrs"
                v-on="on"
                @click="getLike"
              >
                <v-icon>fa-thumbs-up</v-icon>
              </v-btn>
            </template>
            <span>Di que te gusta</span>
          </v-tooltip>
          <span class="mb-0 mt-1 text-center">{{ formatLike(producto.likes) }}</span>
        </v-col>
        <v-col class="text-right">
          <p class="mb-0 subtitle font-weight-black" style="line-height:1.1rem">Precio: $<span class="headline font-weight-black">{{ producto.precio | obtenerEntero }}<span class="subtitle-1 font-weight-black">.{{ producto.precio | obtenerParteDecimal }}</span></span></p>
          <p v-if="producto.descuento" class="mb-0 caption font-weight-light text-right">Descuento: <span class="font-weight-medium">${{ producto.descuento | valorMonetario }}</span></p>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-expand-transition>
      <v-card
        flat
        v-if="reveal"
        class="transition-fast-in-fast-out v-card--reveal"
        color="primary"
        style="height: 100%;"
      >
        <v-card-text class="pa-1">
          <v-virtual-scroll
            :items="producto.ingredientesMenu"
            min-height="280"
            item-height="40"
            @click="reveal = !reveal"
          >
            <template v-slot:default="{ item }">
              <v-list-item class="px-2">
                <v-list-item-content class="white--text">
                    <div class="d-flex align-center">
                      <div>
                        <v-icon small left color="white">fa-check-circle</v-icon>
                      </div>
                      <p class="caption mb-0 label-ingrediente" style="line-height:1rem">
                        {{ item }}
                      </p>
                    </div>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row dense class="justify-end">
            <v-col cols="auto">
              <v-btn
                :loading="loading"
                color="primary"
                depressed
                @click="reveal = !reveal"
              >
                <v-icon left small>
                  fa-solid fa-chevron-down
                </v-icon>
                Entiendo
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>
<script>
import { db } from '@/plugins/firebase'
import { updateDoc, doc } from "firebase/firestore"
import imageFunction from '@/functions/imageFunction'
export default {
  name: 'ProductItem',
  props: {
    producto: {
      type: Object,
      require: true
    }
  },
  data: () => ({
    reveal: false,
    loading: false,
    photoURL: null
  }),
  computed: {
    heightImg () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          return '250'
        case 'lg':
          return '250'
        case 'md':
          return '200'
        case 'sm':
          return '250'
        default:
          return '185'
      }
    }
  },
  watch: {
    producto () {
      this.getImage()
    }
  },
  mounted () {
    this.getImage()
  },
  methods : {
    async getImage () {
			this.photoURL = await imageFunction.getImageStore('inventario', this.producto.id, '.webp', 'md')
		},
    formatLike(likes) {
      if (likes < 500) {
        return likes
      } else {
        return (likes / 1000).toFixed(2) + 'k'
      }
    },
    async getLike () {
      try {
        this.loading = true
        const itemRef = doc(db, 'inventario', this.producto.id);
        await updateDoc(itemRef, {
          likes: this.producto.likes + 1
        })
        .then(()=>{
          this.loading = false
        })
      } catch {
        this.loading = false
      }
    },
    verDataItem () {
      this.$emit('verDataItem')
    }
  }
}
</script>
<style lang="scss">
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute !important;
  width: 100%;
}
.title-product {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  align-content: start;
}
.label-ingrediente {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  align-content: start;
}
.descrition-product {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  align-content: start;
}
.image-item{
  object-fit: cover;
  object-position: center;
  height: 220px;
  width: 220px;
}
</style>
