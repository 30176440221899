<template>
  <span>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="aperturaCaja"
          icon
          color="success"  
          :loading="loading"
          v-bind="attrs"
          v-on="on"
          @click="verCaja"
        >
          <v-icon>
            fa-lock-open
          </v-icon>
        </v-btn>
      </template>
      <span>Abierto el: {{ getDate(user.fechaCaja) }}</span>
    </v-tooltip>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'BtnEstadoCaja',
  props: {
    aperturaCaja: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data: () => ({
    loading:false,
  }),
  computed: {
    ...mapGetters(['negocio','userId', 'user'])
  },
  methods: {
    verCaja() {
      if (this.user.cajaId) {
        this.$router.push(`/caja/detalle/transacciones/${this.user.cajaId}`)          
      }
    },
    getDate (data) {
      try {
        if (data) {
          // const fechaPost = this.$moment(data.toDate())
          const fechaPost = this.$moment.unix(data.seconds)
          return fechaPost.format('YYYY-MM-DD h:mm a');
        } else {
          return null
        }
      } catch {
        return null
      }
    }
  }
}
</script>
<style>
</style>
